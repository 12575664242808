import React, { useEffect, useState } from 'react'
import FullScreenLoader from '../../../Components/FullScreenLoader'
import SectionHeader from '../../../Components/General/SectionHeader'
import AnalyticsPluginConfigurations from '../../../Components/Plugins/AnalyticsPluginConfigurations';
import { editPlugin, getPlugins } from '../../../Requests/User/PluginRequests';
import gaLogo from '../../../Assets/Images/PluginImages/ga_logo.png'
import mailchimpLogo from '../../../Assets/Images/PluginImages/mailchimp_logo.png'
import MailchimpPluginConfigurations from '../../../Components/Plugins/MailchimpPluginConfigurations';
import facebookMessengerLogo from '../../../Assets/Images/PluginImages/facebook_messenger_logo.png'
import FacebookMessengerPluginConfigurations from '../../../Components/Plugins/FacebookMessengerPluginConfigurations';
import { CurrencyEuroIcon } from '@heroicons/react/24/outline';
import PremiumModal from '../../../Components/PremiumModal';
function Plugins() {
    const [plugins, setPlugins] = useState(null);
    const [setupModal, setSetupModal] = useState(null);
    const [premiumModal, setPremiumModal] = useState(null);


    const handleCloseModal = (change) => {
        setSetupModal(null)
        change && getAllPlugins()
    }

    useEffect(() => {
        getAllPlugins()
    }, [])

    const getAllPlugins = () => {
        getPlugins().then((res) => {
            if (res.data.message) {
                if (res.data.message == "Unavailable in current plan") {
                    setPlugins(null)
                    setPremiumModal(<PremiumModal handleClose={() => { setPremiumModal(null) }} />)
                }
            } else {
                setPlugins(res.data)
            }
        })
    }

    return (
        <div className='h-full'>
            {plugins ?
                <div className='flex-1 h-full  mt-2 sm:mt-8 '>
                    <div className="mx-4 xl:mx-auto max-w-7xl px-6 lg:px-8 py-8  bg-white mt-12 rounded-lg ">
                        <SectionHeader title="Plugins" subtitle="Adicione funcionalidades à sua loja!" />
                        <div className='mt-12 mb-4 max-w-5xl mx-auto'>
                            <div className=' space-y-12 md:space-y-0 md:flex justify-start gap-8 items-center'>
                                <div className='basis-1/4 flex flex-col justify-center items-center border rounded-lg gap-2 pt-2'>
                                    <img src={gaLogo} className='basis-3/4 px-6 py-6 object-contain max-h-28'></img>
                                    <p className='text-xs'>Estatístias das visitas ao seu website</p>
                                    <div className='w-full flex flex-col gap-0'>
                                        {plugins.filter((plugin) => plugin.plugin == "GoogleAnalytics").length > 0 ?
                                            <a className='w-full mt-2 py-2 bg-cyan-700 text-white  hover:bg-cyan-600  text-sm font-bold text-center' href={plugins.filter((plugin) => plugin.plugin == "GoogleAnalytics")[0].url} target='_blank'>
                                                Ver painel
                                            </a> :
                                            <div className='w-full mt-2 py-2 bg-white text-white text-sm font-bold text-center'>
                                                <span>&nbsp;&nbsp;</span>
                                            </div>
                                        }
                                        {plugins.filter((plugin) => plugin.plugin == "GoogleAnalytics").length > 0 ?
                                            <button className=' w-full py-2 bg-white text-cyan-700  hover:bg-cyan-600 hover:text-white border border-cyan-700 text-sm font-bold rounded-b-lg'
                                                onClick={() => {
                                                    setSetupModal(<AnalyticsPluginConfigurations closeModal={handleCloseModal} analiticsKey={plugins.filter((plugin) => plugin.plugin == "GoogleAnalytics").length > 0 ? plugins.filter((plugin) => plugin.plugin == "GoogleAnalytics")[0].public_key : null}></AnalyticsPluginConfigurations>)
                                                }}
                                            >
                                                Editar
                                            </button> :
                                            <button className=' w-full py-2 text-white bg-cyan-700  hover:bg-cyan-600 border border-cyan-700 text-sm font-bold rounded-b-lg'
                                                onClick={() => {
                                                    setSetupModal(<AnalyticsPluginConfigurations closeModal={handleCloseModal} analiticsKey={plugins.filter((plugin) => plugin.plugin == "GoogleAnalytics").length > 0 ? plugins.filter((plugin) => plugin.plugin == "GoogleAnalytics")[0].public_key : null}></AnalyticsPluginConfigurations>)
                                                }}
                                            >
                                                Adicionar
                                            </button>
                                        }

                                    </div>
                                </div>
                                <div className='basis-1/4 flex flex-col justify-center items-center border rounded-lg gap-2 pt-2'>
                                    <img src={mailchimpLogo} className='basis-3/4 px-6 py-6 object-contain max-h-28'></img>
                                    <p className='text-xs'>Recolha emails e envie newsletters</p>
                                    <div className='w-full flex flex-col gap-0'>
                                        {plugins.filter((plugin) => plugin.plugin == "Mailchimp").length > 0 ?
                                            <a className='w-full mt-2 py-2 bg-cyan-700 text-white  hover:bg-cyan-600  text-sm font-bold text-center' href={plugins.filter((plugin) => plugin.plugin == "Mailchimp")[0].url} target='_blank'>
                                                Ver painel
                                            </a> :
                                            <div className='w-full mt-2 py-2 bg-white text-white text-sm font-bold text-center'>
                                                <span>&nbsp;&nbsp;</span>
                                            </div>
                                        }
                                        {plugins.filter((plugin) => plugin.plugin == "Mailchimp").length > 0 ?
                                            <button className=' w-full py-2 bg-white text-cyan-700  hover:bg-cyan-600 hover:text-white border border-cyan-700 text-sm font-bold rounded-b-lg'
                                                onClick={() => {
                                                    setSetupModal(<MailchimpPluginConfigurations closeModal={handleCloseModal} analiticsKey={plugins.filter((plugin) => plugin.plugin == "Mailchimp").length > 0 ? plugins.filter((plugin) => plugin.plugin == "Mailchimp")[0].api_key : null} />)
                                                }}
                                            >
                                                Editar
                                            </button> :
                                            <button className=' w-full py-2 text-white bg-cyan-700  hover:bg-cyan-600 border border-cyan-700 text-sm font-bold rounded-b-lg'
                                                onClick={() => {
                                                    setSetupModal(<MailchimpPluginConfigurations closeModal={handleCloseModal} analiticsKey={plugins.filter((plugin) => plugin.plugin == "Mailchimp").length > 0 ? plugins.filter((plugin) => plugin.plugin == "Mailchimp")[0].public_key : null} />)
                                                }}
                                            >
                                                Adicionar
                                            </button>
                                        }

                                    </div>
                                </div>
                                <div className='basis-1/4 flex flex-col justify-center items-center border rounded-lg gap-2 pt-2'>
                                    <img src={facebookMessengerLogo} className='basis-3/4 px-6 py-6 object-contain max-h-28'></img>
                                    <p className='text-xs'>Chat na sua página, com o messenger</p>
                                    <div className='w-full flex flex-col gap-0'>
                                        {plugins.filter((plugin) => plugin.plugin == "FacebookMessenger").length > 0 ?
                                            <a className='w-full mt-2 py-2 bg-cyan-700 text-white  hover:bg-cyan-600  text-sm font-bold text-center' href={plugins.filter((plugin) => plugin.plugin == "FacebookMessenger")[0].url} target='_blank'>
                                                Ver painel
                                            </a> :
                                            <div className='w-full mt-2 py-2 bg-white text-white text-sm font-bold text-center'>
                                                <span>&nbsp;&nbsp;</span>
                                            </div>
                                        }
                                        {plugins.filter((plugin) => plugin.plugin == "FacebookMessenger").length > 0 ?
                                            <button className=' w-full py-2 bg-white text-cyan-700  hover:bg-cyan-600 hover:text-white border border-cyan-700 text-sm font-bold rounded-b-lg'
                                                onClick={() => {
                                                    setSetupModal(<FacebookMessengerPluginConfigurations closeModal={handleCloseModal} facebookMessengerId={plugins.filter((plugin) => plugin.plugin == "FacebookMessenger").length > 0 ? plugins.filter((plugin) => plugin.plugin == "FacebookMessenger")[0].public_key : null} />)
                                                }}
                                            >
                                                Editar
                                            </button> :
                                            <button className=' w-full py-2 text-white bg-cyan-700  hover:bg-cyan-600 border border-cyan-700 text-sm font-bold rounded-b-lg'
                                                onClick={() => {
                                                    setSetupModal(<FacebookMessengerPluginConfigurations closeModal={handleCloseModal} facebookMessengerId={plugins.filter((plugin) => plugin.plugin == "FacebookMessenger").length > 0 ? plugins.filter((plugin) => plugin.plugin == "FacebookMessenger")[0].public_key : null} />)
                                                }}
                                            >
                                                Adicionar
                                            </button>
                                        }

                                    </div>
                                </div>
                                <div className='basis-1/4 flex flex-col justify-center items-center border rounded-lg gap-2 pt-2'>
                                    <CurrencyEuroIcon className='basis-3/4 px-6 py-6 object-contain max-h-28 text-green-500 stroke-1'></CurrencyEuroIcon>

                                    <p className='text-xs'>Pagamentos Manuais</p>
                                    <div className='w-full flex flex-col gap-0'>
                                        {plugins.filter((plugin) => plugin.plugin == "Manual Payments").length > 0 ?
                                            <a className='w-full mt-2 py-2 bg-cyan-700 text-white  hover:bg-cyan-600  text-sm font-bold text-center rounded-b-lg' href={plugins.filter((plugin) => plugin.plugin == "Manual Payments")[0]?.url} target='_blank'>
                                                Ver painel
                                            </a> :
                                            <div className='w-full mt-2 py-2 bg-white text-white text-sm font-bold text-center'>
                                                <span>&nbsp;&nbsp;</span>
                                            </div>
                                        }
                                        {plugins.filter((plugin) => plugin.plugin == "Manual Payments").length < 1 &&

                                            <button className=' w-full py-2 text-white bg-cyan-700  hover:bg-cyan-600 border border-cyan-700 text-sm font-bold rounded-b-lg'
                                                onClick={() => {
                                                    editPlugin({ "plugin": "Manual Payments", "public_key": "", "url": "https://osite.pt/billing" }).then((res) => {
                                                        getAllPlugins()
                                                    })
                                                }}
                                            >
                                                Adicionar
                                            </button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex justify-end align-bottom'>
                            <a className='text-cyan-600 text-end text-sm mt-2' href='https://ositept.tawk.help/category/plugins' target='_blank'>Precisa de ajuda? Clique aqui!</a>
                        </div>
                    </div>

                </div > :
                <FullScreenLoader />
            }
            {setupModal}
            {premiumModal}
        </div >
    )
}

export default Plugins
