import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function getPosts() {
    var url = process.env.REACT_APP_API_BASE_URL + "/posts";
    return axios.get(url);
}
export function getPostBySlug(id) {
    var url = process.env.REACT_APP_API_BASE_URL + "/posts/" + id;
    return axios.get(url);
}
