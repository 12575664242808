
import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function editPlugin(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/plugin";
    return axios.post(url, { ...data }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getPlugins() {
    var url = process.env.REACT_APP_API_BASE_URL + "/plugins";
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}


export function submitNewsletterEmail(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/add_to_newsletter";
    return axios.post(url, { ...data }, { headers: { authorization: `Bearer ${getToken()}` } });
}
