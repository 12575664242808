import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
  const cookies = new Cookies();
  return cookies.get("token");
}

export function getExercises() {
  var url = process.env.REACT_APP_API_BASE_URL + "/exercises";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function createNewWorkout(name, description) {
  var url = process.env.REACT_APP_API_BASE_URL + "/create_workout";
  return axios.post(url, { name: name, description: description }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getWorkout(workout_id) {
  var url = process.env.REACT_APP_API_BASE_URL + "/workout";
  return axios.post(url, { workout_id: workout_id }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getWorkoutExercises(workout_id) {
  var url = process.env.REACT_APP_API_BASE_URL + "/workout/detailed";
  return axios.post(url, { workout_id: workout_id }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getAllWorkouts() {
  var url = process.env.REACT_APP_API_BASE_URL + "/my_workouts";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function updateWorkout(data) {
  var url = process.env.REACT_APP_API_BASE_URL + "/workout/edit";
  return axios.post(url, { ...data }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function addExercisesToWorkout(data, workout) {
  var url = process.env.REACT_APP_API_BASE_URL + "/add_exercises_to_workout";
  data.map((item, index) => {
    item["position"] = index
    item["uuid"] = item.id
    item["workout_id"] = workout
  })
  return axios.post(url, { data }, { headers: { authorization: `Bearer ${getToken()}` } });

}

export function getAccountStatus() {
  var url = process.env.REACT_APP_API_BASE_URL + "/account_status";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });

}


export function getDomain(domainName) {
  var url = process.env.REACT_APP_API_BASE_URL + "/get_domain/" + domainName;
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function updateStoreSettings(data) {
  var url = process.env.REACT_APP_API_BASE_URL + "/update_store_settings";
  return axios.post(url, { ...data }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function updateSeo(data) {
  var url = process.env.REACT_APP_API_BASE_URL + "/update_seo";
  return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function changeStoreStatus(data) {
  var url = process.env.REACT_APP_API_BASE_URL + "/change_store_status";
  return axios.post(url, { ...data }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function endUsers(data) {
  var url = process.env.REACT_APP_API_BASE_URL + "/end_users";
  return axios.post(url, { ...data }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function sendReport(data) {
  var url = process.env.REACT_APP_API_BASE_URL + "/get_report";
  return axios.post(url, { ...data }, { headers: { authorization: `Bearer ${getToken()}` } })
}
