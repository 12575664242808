import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
    const cookies = new Cookies();
    return cookies.get("token");
}

export function getVendorAccountStatus() {
    var url = process.env.REACT_APP_API_BASE_URL + "/vendor_account_status";
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}


export function createNewProduct(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/products";
    return axios.post(url, { ...data }, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getProductsByUrl(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/get_products_by_url";
    return axios.post(url, { ...data });
}

export function getProduct(id) {
    var url = process.env.REACT_APP_API_BASE_URL + "/product/" + id;
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getProductCategories() {
    var url = process.env.REACT_APP_API_BASE_URL + "/product_categories";
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function addProductCategories(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/product_categories";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}


export function updatePurchase(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/update_purchase"
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function deleteProduct(id) {
    var url = process.env.REACT_APP_API_BASE_URL + "/product/" + id + "/delete"
    return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function deleteProductCategories(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/product_categories/delete";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function editProductCategories(data) {
    var url = process.env.REACT_APP_API_BASE_URL + "/product_categories/edit";
    return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function addImageToProduct(data) {
    const formData = new FormData();
    formData.append('product_id', data['product_id']);
    formData.append('image_order', data['image_order']);
    formData.append('image', data['image']);
    var url = process.env.REACT_APP_API_BASE_URL + "/product_image";
    return axios.post(url, formData, {
        headers: {
            authorization: `Bearer ${getToken()}`,
            'Content-Type': 'multipart/form-data',
        }
    });
}
