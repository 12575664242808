import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDoubleDownIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EyeIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SectionHeader from '../../../Components/General/SectionHeader';
import Loader from '../../../Components/Loader';
import { formatCurrency } from '../../../utils/formaters';
import { endUsers } from '../../../Requests/User/AppRequests';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function EndUserList() {
    const [page, setPage] = useState(1)
    const [users, setUsers] = useState(null)


    useEffect(() => {
        endUsers({ "page": page }).then((res) => {
            setUsers(res.data)
        })
    }, [page])

    return (
        <div className='h-full'>
            <div className="mx-4 xl:mx-auto max-w-7xl px-6 lg:px-8 py-8  bg-white mt-24 rounded-lg ">
                <div className='flex flex-col lg:flex-row pt-4 justify-start gap-4 lg:gap-0 lg:justify-between lg:items-center'>
                    <h3 className="text-2xl font-base leading-6 text-cyan-600 ">Utilizadores</h3>
                </div>

                <div className="space-y-6 sm:space-y-5 mt-6 border-t-4 border-cyan-600 pt-4">
                    <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
                        {users ?
                            <div>
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>

                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                            >
                                                Nome
                                            </th>
                                            <th
                                                scope="col"
                                                className=" lg:table-cell py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                            >
                                                Email
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.data && users.data.map((user) => (
                                            <>
                                                <tr key={user.id} className="border-b border-gray-200 ba">
                                                    <td className="py-4 px-3 text-left text-sm text-gray-500 lg:table-cell">{user && (user?.name ? user.name : "-")}</td>
                                                    <td className="py-4 px-3 text-right text-sm text-gray-500 lg:table-cell">{user && (user?.email ? user.email : "-")}</td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>

                                </table>

                                <div className="flex items-end justify-center bg-white px-4 py-3 sm:px-6 w-full mt-4">
                                    <div className="flex flex-col items-center justify-center">
                                        <div>
                                            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                                {[...Array(users.last_page)].map((x, i) => {
                                                    return (<button
                                                        type='button'
                                                        onClick={() => { setPage(i + 1) }}
                                                        className=
                                                        {"relative z-10 inline-flex items-center  px-4 py-2 text-sm font-semibold  focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600 " + (i + 1 == users.current_page ? "text-white bg-cyan-600" : "text-cyan-600 bg-white border")}
                                                    >
                                                        {i + 1}
                                                    </button>)
                                                })}
                                            </nav>
                                        </div>
                                        <div>
                                            <p className="text-xs mt-2 text-gray-700">
                                                A mostrar <span className="font-medium">{users.data.length}</span> de {' '}
                                                <span className="font-medium">{users.total}</span> encomendas
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            :
                            <div className='w-full flex justify-center'>
                                <Loader ></Loader>
                            </div>
                        }
                    </div>
                </div>
                <div className='w-full flex justify-end align-bottom'>
                    <a className='text-cyan-600 text-end text-sm' href='https://ositept.tawk.help/category/produtos' target='_blank'>Precisa de ajuda? Clique aqui!</a>
                </div>
            </div >
        </div >
    )
}

export default EndUserList
