import React, { useEffect, useState } from 'react'
import FullScreenLoader from '../Components/FullScreenLoader'
import { getDomain } from '../Requests/User/AppRequests'
import MainRouter from './MainRouter'
import WebsiteRouter from './WebsiteRouter'
import ReactGA from "react-ga4"
function RouterChooser() {
    const [router, setRouter] = useState(null)

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search });

        let hostname = window.location.hostname.replace("www.", "")
        if (hostname.split(".")[0] === process.env.REACT_APP_DOMAIN_SECTION.split(":")[0]) {
            //setRouter(<MainRouter></MainRouter>)
            /* Local only */

            setRouter(<MainRouter></MainRouter>)
        } else {
            if (hostname.split(".")[1] === process.env.REACT_APP_DOMAIN_SECTION.split(":")[0]) {
                setRouter(<WebsiteRouter page_url={hostname.split(".")[0]}></WebsiteRouter>)
            } else {
                getDomain(hostname).then((res) => {
                    setRouter(<WebsiteRouter page_url={res.data.landing} seo={res.data}></WebsiteRouter>)
                })
            }
        }
    }, [])

    return (
        <div className='h-full'>
            {router ? router : <FullScreenLoader></FullScreenLoader>}
        </div>
    )
}

export default RouterChooser
