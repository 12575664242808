import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  FaceFrownIcon, NoSymbolIcon

} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export default function PremiumModal(props) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => { props.handleClose(); setOpen() }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>

                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Opção não disponivel!
                    </Dialog.Title>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-200 mt-4">
                      <NoSymbolIcon
                        className="h-6 w-6 text-red-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        O seu plano não permite que realize esta acção
                      </p>
                      <p className="text-sm  text-cyan-600">
                        Altere o seu plano para continuar
                      </p>

                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    className="w-full text-base text-white bg-gradient-to-r from-sky-900 to-sky-700 rounded-lg py-2 px-4 animate-pulse"
                    onClick={() => navigate("/user_plans")}
                  >
                    Alterar plano
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
