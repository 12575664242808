import Cookies from "universal-cookie";
import axios from "axios";

function getToken() {
  const cookies = new Cookies();
  return cookies.get("token");
}

export function removeToken() {
  const cookies = new Cookies();
  cookies.remove("token");
  cookies.remove("page_url");

}

export function login(email, password) {
  var url = process.env.REACT_APP_API_BASE_URL + `/login`;
  return axios.post(url, {
    email: email,
    password: password,
  });
}

export function logout() {
  var url = process.env.REACT_APP_API_BASE_URL + `/logout`;
  var logoutToken = getToken()
  removeToken()
  return axios.get(url, { headers: { authorization: `Bearer ${logoutToken}` } });
}

export function register(name, email, password, password_confirmation, email_promotions = false, campaign = null) {
  var url = process.env.REACT_APP_API_BASE_URL + `/register`;
  return axios.post(url, {
    name: name,
    email: email,
    password: password,
    password_confirmation: password_confirmation,
    email_promotions: email_promotions,
    campaign: campaign
  });
}

export function confirmEmail(confirmation_token) {
  var url = process.env.REACT_APP_API_BASE_URL + `/confirm_email`;
  return axios.post(url, {
    confirmation_token: confirmation_token,
  });
}


export function getAvailablePlans() {
  var url = process.env.REACT_APP_API_BASE_URL + "/billing/plans";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getSubscriptionUpdateValue(data) {
  var url = process.env.REACT_APP_API_BASE_URL + "/update_subscription";
  return axios.post(url, data, { headers: { authorization: `Bearer ${getToken()}` } });

}

export function createNewSubscription(priceId, language = "pt") {
  var url = process.env.REACT_APP_API_BASE_URL + "/billing/checkout";
  return axios.post(
    url,
    { "price_id": priceId, "language": language },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function createNewPremiumSubscription(period) {
  var url = process.env.REACT_APP_API_BASE_URL + "/billing/premium/checkout";
  return axios.post(
    url,
    { "type": period },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}


export function getSubscriptions() {
  var url = process.env.REACT_APP_API_BASE_URL + "/subscriptions";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function cancelSubscriptionRenewal(subscription_id) {
  var url = process.env.REACT_APP_API_BASE_URL + "/cancel_subscription_renewal";
  return axios.post(
    url,
    { subscription_id: subscription_id },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function reactivateSubscriptionRenewal(subscription_id) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/reactivate_subscription_renewal";
  return axios.post(
    url,
    { subscription_id: subscription_id },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function requestPasswordChange(email) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/request_password_change";
  return axios.post(
    url,
    {
      email: email
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function changePassword(confirmation_token, password, password_confirmation) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/change_password";
  return axios.post(
    url,
    {
      confirmation_token: confirmation_token, password: password,
      password_confirmation: password_confirmation
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function updatePassword(old_password, password, password_confirmation) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/setup_new_password";
  return axios.post(
    url,
    {
      old_password: old_password,
      password: password,
      password_confirmation: password_confirmation
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function updateEmailSettings(email_updates, email_promotions) {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/update_email_settings";
  return axios.post(
    url,
    {
      email_updates: email_updates,
      email_promotions: email_promotions
    },
    { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function getUserSettings() {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/user_settings";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function deleteUser() {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/delete";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } }
  );
}

export function getUserId() {
  var url =
    process.env.REACT_APP_API_BASE_URL + "/user_id";
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function registerEndUser(name, email, password, password_confirmation, page_url) {
  var url = process.env.REACT_APP_API_BASE_URL + `/register_end_user`;
  return axios.post(url, {
    name: name,
    email: email,
    password: password,
    password_confirmation: password_confirmation,
    url: page_url
  });
}

export function softRegister(email, page_url) {
  var url = process.env.REACT_APP_API_BASE_URL + `/soft_register`;
  return axios.post(url, {
    email: email,
    url: page_url
  });
}

export function getUsers() {
  var url = process.env.REACT_APP_API_BASE_URL + `/users`;
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getPurchases(page = 1, filters = null) {
  var url = process.env.REACT_APP_API_BASE_URL + `/all_purchases?page=` + page;
  return axios.post(url, filters, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function getSetupStatus() {
  var url = process.env.REACT_APP_API_BASE_URL + `/setup_status`;
  return axios.get(url, { headers: { authorization: `Bearer ${getToken()}` } });
}

export function addUserToMailingList(data) {
  var url = process.env.REACT_APP_API_BASE_URL + "/mailchimp_subscribe";
  return axios.post(url, data);
}

export function endUserLogin(email, password, page_url) {
  var url = process.env.REACT_APP_API_BASE_URL + `/end_user_login`;
  return axios.post(url, {
    email: email,
    password: password,
    url: page_url
  });
}
