import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { addProductCategories } from "../../Requests/User/ProductRequests";
import { editPlugin } from "../../Requests/User/PluginRequests";

export default function MailchimpPluginConfigurations({ closeModal, analiticsKey }) {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();

    const close = () => {
        setOpen(false)
        closeModal(false)
    }

    const submitMetricsId = (event) => {
        event.preventDefault()
        const mailchimp_api_key = event.target.mailchimp_api_key.value
        const mailchimp_list_id = event.target.mailchimp_list_id.value
        editPlugin({ "plugin": "Mailchimp", "api_key": mailchimp_api_key, "url": "https://login.mailchimp.com/", "meta": mailchimp_list_id }).then((res) => {
            closeModal(true)
        })

    }

    return (
        <Transition.Root show={open} as={Fragment}>

            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 text-center mb-2"
                                    >
                                        Adicionar Mailchimp
                                    </Dialog.Title>

                                    <div className="mt-3 text-center sm:mt-5">
                                        <form onSubmit={submitMetricsId}>
                                            <div className="mt-2">
                                                <label htmlFor="text" className=" text-sm font-medium text-gray-700 mt-2 w-full">
                                                    Insira a sua Api Key
                                                </label>
                                                <div className="w-full mt-1">
                                                    <input
                                                        id="mailchimp_api_key"
                                                        name="text"
                                                        type="mailchimp_api_key"
                                                        autoComplete="mailchimp_api_key"
                                                        required
                                                        defaultValue={analiticsKey}
                                                        className=" w-full appearance-none rounded-md border bg-gray-100 border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 text-sm"

                                                    />
                                                </div>
                                                <label htmlFor="text" className=" text-sm font-medium text-gray-700 mt-2 w-full">
                                                    Insira o id da lista
                                                </label>
                                                <div className="w-full mt-1">
                                                    <input
                                                        id="mailchimp_list_id"
                                                        name="text"
                                                        type="mailchimp_list_id"
                                                        autoComplete="mailchimp_list_id"
                                                        required
                                                        defaultValue={analiticsKey}
                                                        className=" w-full appearance-none rounded-md border bg-gray-100 border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 text-sm"

                                                    />
                                                </div>
                                                <div className="mt-5 sm:mt-6">
                                                    <button
                                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2 sm:text-sm"
                                                    >
                                                        Guardar
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                    <div className="mt-4 flex justify-center items-center">
                                        <a className=" w-full text-center text-xs text-cyan-600" href="https://ositept.tawk.help/article/como-configurar-google-analytics" target="_blank">Como obtenho uma API Key e um id de lista?</a>
                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    );
}
