import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getPostBySlug } from '../../Requests/User/Blog'
import logo from "../../Assets/logo.png";

const navigation = [
    { name: 'Início', href: '/' },
    { name: 'Blog', href: '/blog' },
]

function BlogPost() {
    const { post_id } = useParams()

    const [blogPost, setBlogPost] = useState(null)
    const [previousBlogPost, setPreviousBlogPost] = useState(null)
    const [nextBlogPost, setNextBlogPost] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        getPostBySlug(post_id).then((res) => {
            setBlogPost(res.data.post)
            setNextBlogPost(res.data.next)
            setPreviousBlogPost(res.data.previous)
        })
    }, [])

    return (
        <div>
            {blogPost &&
                <div className="isolate bg-white ">
                    <nav className="flex items-center justify-between px-6  lg:px-8 py-4" aria-label="Global">
                        <div className="flex lg:flex-1 items-center">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">Osite.pt</span>
                                <img className="h-10 sm:h-12" src={logo} alt="" />
                            </a>

                            <span className="text-cyan-600 text-base sm:text-xl font-bold ml-4">OSITE.PT</span>
                        </div>

                        <div className="hidden lg:flex lg:gap-x-12">
                            {navigation.map((item) => (
                                <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-black">
                                    {item.name}
                                </a>
                            ))}
                        </div>

                        <div className=" lg:flex lg:flex-1 lg:justify-end">
                            <a href="/login" className="text-sm font-semibold leading-6 text-white bg-cyan-600 rounded-md px-6 py-3">
                                Entrar <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </nav>
                    <div className='w-auto  flex justify-center bg-gray-200 pb-12'>
                        <div className=' lg:p-4 text-white max-w-5xl text-justify '>
                            <div className='flex flex-col  gap-8'>
                                <div className='flex flex-col bg-white px-8 xl:px-8 rounded-md py-8'>
                                    <div className='text-4xl md:text-5xl text-center font-bold my-8 text-sky-800' >{blogPost.title}</div>
                                    <div className='w-full flex justify-center'>
                                        <img src={process.env.REACT_APP_DO_SPACES_URL + "/" + blogPost.image} className='w-fit lg:max-w-3xl aspect-video object-cover'></img>
                                    </div>
                                    <div class="px-4 md:px-12 prose prose-lg prose-indigo max-w-5xl mx-auto mt-6 text-sky-800 sm:text-sm lg:text-base xl:text-lg text-justify my-24" dangerouslySetInnerHTML={{ __html: blogPost.body }} />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            }
            <div className='w-full  flex justify-center pb-12'>
                <div className={(previousBlogPost && nextBlogPost) ? 'flex justify-between max-w-5xl w-full gap-8' : 'justify-between max-w-5xl w-full gap-8'} >
                    {previousBlogPost && <div className='w-full  flex-col justify-center pb-12  basis-1/2'>
                        <div className='w-full bg-sky-800 text-white px-4 py-4 text-center text-xl rounded-t-md'> Anterior</div>
                        <div className='flex flex-col bg-white  px-8 rounded-md py-8 '>
                            <div className='text-2xl text-center font-bold my-8 text-sky-800 basis-1/4' >{previousBlogPost.title}</div>
                            <div className='flex justify-center basis-1/4'>
                                <img src={process.env.REACT_APP_DO_SPACES_URL + "/" + previousBlogPost.image} className=' w-82 aspect-video object-cover'></img>
                            </div>
                            <div class="prose prose-lg prose-indigo max-w-5xl mx-auto mt-6 text-sky-800 sm:text-sm lg:text-base xl:text-lg text-justify my-12 basis-1/4" dangerouslySetInnerHTML={{ __html: previousBlogPost.excerpt }} />
                            <div className='flex justify-center basis-1/4'>
                                <a className='rounded-md bg-sky-800 text-white py-2 px-8 px-auto'
                                    type='button'
                                    href={"/blog/" + previousBlogPost.slug}
                                >Ler mais</a>
                            </div>
                        </div>
                    </div>
                    }
                    {nextBlogPost && <div className='w-full  flex-col justify-center pb-12  basis-1/2'>
                        <div className='w-full bg-sky-800 text-white px-4 py-4 text-center text-xl rounded-t-md'> Próximo</div>
                        <div className='flex flex-col bg-white  px-8 rounded-md py-8 '>

                            <div className='text-2xl text-center font-bold my-8 text-sky-800 basis-1/4' >{nextBlogPost.title}</div>
                            <div className='flex justify-center basis-1/4'>
                                <img src={process.env.REACT_APP_DO_SPACES_URL + "/" + nextBlogPost.image} className=' w-82 aspect-video object-cover'></img>
                            </div>
                            <div class="prose prose-lg prose-indigo max-w-5xl mx-auto mt-6 text-sky-800 sm:text-sm lg:text-base xl:text-lg text-justify my-12 basis-1/4" dangerouslySetInnerHTML={{ __html: nextBlogPost.excerpt }} />
                            <div className='flex justify-center basis-1/4'>
                                <a className='rounded-md bg-sky-800 text-white py-2 px-8 px-auto'
                                    type='button'
                                    href={"/blog/" + nextBlogPost.slug}
                                >Ler mais</a>
                            </div>
                        </div>
                    </div>
                    }


                </div>
            </div>
        </div >
    )
}

export default BlogPost
