
import "../App.css";
import Cookies from "universal-cookie";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CookieConsent from 'react-cookie-consent';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import FullScreenLoaderBlack from "../Components/FullScreenLoaderBlack";
import { Helmet } from "react-helmet";

const LandingViewer = lazy(() => import("../Pages/Private/NewWebsite/LandingViewer"));
const NotFound = lazy(() => import('../Pages/Public/NotFound'));


function WebsiteRouter({ page_url, seo }) {
    const [seoFields, setSeoFields] = useState(seo || null)
    return (
        <BrowserRouter >
            <Suspense fallback={<FullScreenLoaderBlack />}>
                {seoFields &&
                    <Helmet>

                        <title>{seoFields?.title}</title>
                        <meta name="description" content={seoFields?.description} />
                        <link rel="icon" href={process.env.REACT_APP_DO_SPACES_URL + "/" + seoFields?.image_url + "/blob-xs.jpg" + `?timestamp=${new Date().getTime()} `} />
                        <meta property="og:title" content={seoFields?.title} />
                        <meta property="og:description" content={seoFields?.description} />
                        <meta property="og:url" content={"https://" + seoFields?.domain} />
                        <meta property="og:image:secure_url" itemprop="image" content={process.env.REACT_APP_DO_SPACES_URL + "/" + seoFields?.image_url + "/blob-xs.jpg" + `?timestamp=${new Date().getTime()} `} />
                        <meta name="twitter:title" content={seoFields?.title} />
                        <meta name="twitter:description" content={seoFields?.description} />
                        <meta name="twitter:image" content={process.env.REACT_APP_DO_SPACES_URL + "/" + seoFields?.image_url + "/blob-xs.jpg" + `?timestamp=${new Date().getTime()} `} />
                        <meta name="twitter:card" content={process.env.REACT_APP_DO_SPACES_URL + "/" + seoFields?.image_url + "/blob-xs.jpg" + `?timestamp=${new Date().getTime()}  `} />
                    </Helmet>
                }
                <Routes>
                    <Route path="/course/:course_id" element={<LandingViewer className="h-full" page_url={page_url} />} />
                    <Route path="/item/:product_id" element={<LandingViewer className="h-full" page_url={page_url} />} />
                    <Route path="/change_password/:confirmation_token" element={<LandingViewer className="h-full" page_url={page_url} />} />
                    <Route path="/:path" element={<LandingViewer className="h-full" page_url={page_url} />} />
                    <Route path="/" element={<LandingViewer className="h-full" page_url={page_url} />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>

        </BrowserRouter >
    )
}

export default WebsiteRouter
