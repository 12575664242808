

import "./App.css";
import RouterChooser from "./Routers/RouterChooser";

function App() {
  return (
    <RouterChooser></RouterChooser>
  );
}
//<BrowserRouter basename={window.location.pathname || ''}>
//<Route exact path="/login" element={Boolean(token)?<Login/>:<Navigate to="/register" replace/>}/>
export default App;
