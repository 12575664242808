import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../../Requests/User/UserRequests";
import register_brain from "../../Assets/Images/background1.jpeg";

import logo from "../../Assets/logo.png";
import SuccesModal from "../../Components/SuccesModal";
import ErrorModal from "../../Components/ErrorModal";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import Cookies from "universal-cookie";


function password_validate(password) {
  var re = {
    'capital': /[A-Z]/,
    'digit': /[0-9]/,
  };
  return re.capital.test(password) &&
    re.digit.test(password) &&
    (password.length > 7 && password.length < 20);
}

function Register() {
  const [goToLogin, setGoToLogin] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [passwordWarning, setPasswordWarning] = useState(false);

  let handleCloseModal = () => {
    setFeedbackModal(null)
  }

  let handleSubmit = (event) => {
    setFeedbackModal(false)
    setIsLoading(true);
    event.preventDefault();
    if (!password_validate(event.target.password.value)) {
      setPasswordWarning(true)
      setIsLoading(false)
      return
    }
    let response = register(
      event.target.name.value,
      event.target.email.value,
      event.target.password.value,
      event.target.password_confirmation.value,
      !event.target.email_promotions.checked,
      "organic_landing"
    );
    response.then((res) => {
      setIsLoading(false);
      const cookies = new Cookies();
      cookies.set("token", res.data.token);
      cookies.set("user_id", res.data.user_id);
      cookies.set("analitics_token", res.data.user_id)
      setFeedbackModal(
        <SuccesModal
          title={t('RegisterPage.SuccessModalTitle')}
          body={t('RegisterPage.SuccessModalText')}
          continue={t('RegisterPage.SuccessModalButton')}
          navLink="/first_setup"
          closeModal={handleCloseModal}
        />
      );
    })
      .catch((error) => {
        setIsLoading(false)
        error?.response?.data?.errors?.email ? (
          setFeedbackModal(
            <ErrorModal
              title={t('RegisterPage.FailModalTitle')}
              body={"O email introduzido já tem uma conta."}
              continue={t('RegisterPage.FailModalButton')}
              closeModal={handleCloseModal}
            />
          )) : error?.response?.data?.errors?.password ? (
            setFeedbackModal(
              <ErrorModal
                title={t('RegisterPage.FailModalTitle')}
                body={"As passwords introduzidas não são iguais."}
                continue={t('RegisterPage.FailModalButton')}
                closeModal={handleCloseModal}
              />
            )) : <ErrorModal
          title={t('RegisterPage.FailModalTitle')}
          body={t('RegisterPage.FailModalText')}
          continue={t('RegisterPage.FailModalButton')}
          closeModal={handleCloseModal}
        />

      });
  };



  function redirectToLogin() {
    setGoToLogin(true);
  }

  useEffect(() => {
    if (goToLogin) {
      navigate(`/first_setup`);
    }

  }, [goToLogin]);

  return (
    <div className="h-full bg-white ">
      <div className="min-h-screen flex ">
        <div className="relative hidden w-0 flex-1 lg:block ">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={register_brain}
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className="w-full flex justify-center">
                <img className="h-48 w-auto" src={logo} alt="Your Company" />
              </div>
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                {t('RegisterPage.PageTitle')}
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6 w-full" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.NameField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-600 focus:outline-none focus:ring-cyan-600 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.EmailField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-600 focus:outline-none focus:ring-cyan-600 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.PasswordField')}
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={() => { setPasswordWarning(false) }}
                        id="password"
                        name="password"
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-600 focus:outline-none focus:ring-cyan-600 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('RegisterPage.ConfirmPasswordField')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="password_confirmation"
                        name="password_confirmation"
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-600 focus:outline-none focus:ring-cyan-600 sm:text-sm"
                      />
                    </div>
                  </div>
                  {passwordWarning && <div className="text-sm text-red-600">{t('RegisterPage.PasswordWarning')}</div>}
                  <div className="ml-0 relative flex items-center mt-2">
                    <div className="flex h-5 items-center">
                      <input
                        id="email_promotions"
                        name="email_promotions"
                        type="checkbox"
                        defaultChecked={false}
                        className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-600"
                      //onClick={}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="email_promotions" className="text-xs text-gray-700">
                        Não quero receber promoções e descontos via email
                      </label>
                    </div>
                  </div>
                  <div className="text-sm text-justify">{t('RegisterPage.PrivacyPolicy1')}<a href='/PP.pdf' download className="text-cyan-600">{t('RegisterPage.PrivacyPolicy2')}</a> {t('RegisterPage.PrivacyPolicy3')}  <a href='/T&C.pdf' download className="text-cyan-600">{t('RegisterPage.PrivacyPolicy4')}</a></div>

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <a
                        onClick={redirectToLogin}
                        className="font-medium text-cyan-600 hover:text-cyan-600"
                      >
                        {t('RegisterPage.LoginLink')}
                      </a>
                    </div>
                  </div>

                  <div><button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2"
                  >
                    {isLoading ? <Loader /> :
                      t('RegisterPage.SubmitButton')
                    }
                  </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {feedbackModal}
      </div>
    </div>
  );
}

export default Register;
