import React from 'react'
import Loader from './Loader'

function FullScreenLoaderBlack() {
    return (
        <div className=' h-full bg-zinc-900/20 flex justify-center items-center'>
            <Loader bg={"white"} ></Loader>
        </div>
    )
}

export default FullScreenLoaderBlack
