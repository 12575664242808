import React, { Suspense, lazy, useEffect, useState } from 'react';
import "../App.css";
import Login from "../Pages/Public/Login";
import Register from "../Pages/Public/Register";
import Cookies from "universal-cookie";
import { BrowserRouter, Routes, Navigate, Route, useParams } from "react-router-dom";
import FullScreenLoaderBlack from '../Components/FullScreenLoaderBlack';
import Plugins from '../Pages/Private/Plugins/Plugins';
import BlogPost from '../Pages/Website/BlogPost';
import EndUserList from '../Pages/Private/EndUserViews/EndUserList';
import { clarity } from 'react-microsoft-clarity';
import ReactGA from "react-ga4"



const Landing = lazy(() => import("../Pages/Public/Landing"));
const Blog = lazy(() => import("../Pages/Website/Blog"));
const Questionaire = lazy(() => import("../Pages/Public/Questionaire"));
const PromotionLanding = lazy(() => import('../Pages/Public/PromotionLanding'));
const RegisterPremium = lazy(() => import('../Pages/Public/RegisterPremium'));
const VideoPreviewer = lazy(() => import('../Pages/Private/Video/VideoPreviewer'));
const DocumentationLobby = lazy(() => import('../Components/Documentation/DocumentationLobby'));
const ChoosePlans = lazy(() => import("../Pages/Private/Settings/Payments/ChoosePlans"));
const StoreSettings = lazy(() => import("../Pages/Private/Settings/StoreSettings"));
const ViewPlans = lazy(() => import("../Pages/Private/Settings/Payments/ViewPlans"));
const UserSettings = lazy(() => import("../Pages/Private/Settings/UserSettings"));
const BillingController = lazy(() => import("../Pages/Private/Settings/Payments/BillingController"));
const ViewSubscriptions = lazy(() => import("../Pages/Private/Settings/Payments/ViewSubscriptions"));
const EmailConfirmation = lazy(() => import("../Pages/Public/EmailConfirmation"));
const RequestPasswordChange = lazy(() => import("../Pages/Public/RequestPasswordChange"));
const PasswordChange = lazy(() => import("../Pages/Public/PasswordChange"));
const CookieConsent = lazy(() => import("react-cookie-consent"));
const AccountSettings = lazy(() => import("../Pages/Private/Settings/Account/AccountSettings"));
const NotFound = lazy(() => import("../Pages/Public/NotFound"));
const CreateWorkoutSteps = lazy(() => import("../Pages/Private/Workouts/CreateWorkoutSteps"));
const DocumentCreator = lazy(() => import("../Pages/Private/PdfGen/DocumentCreator"));
const LangingPage = lazy(() => import("../Pages/Private/LandingPages/LangingPage"));
const LandingEditor = lazy(() => import("../Pages/Private/LandingPages/LandingEditor"));
const BillingSetup = lazy(() => import("../Pages/Private/Billing/BillingSetup"));
const DetailItemPage = lazy(() => import("../Pages/Private/UserPages/DetailItemPage"));
const UserAreaPage = lazy(() => import("../Pages/Private/LandingPages/UserAreaPage"));
const MobileDocumentCreator = lazy(() => import("../Pages/Private/PdfGen/MobileDocumentCreator"));
const DocumentSaver = lazy(() => import("../Pages/Private/PdfGen/DocumentSaver"));
const VideoViewer = lazy(() => import("../Pages/Private/Video/VideoViewer"));
const CourseCreator = lazy(() => import("../Pages/Private/Video/CourseCreator"));
const CoursePartsEditor = lazy(() => import("../Pages/Private/Video/CoursePartsEditor"));
const VideoUpload = lazy(() => import("../Pages/Private/Video/VideoUpload"));
const ProductCreator = lazy(() => import("../Pages/Products/ProductCreator"));
const ListProducts = lazy(() => import("../Pages/Private/Billing/ListProducts"));
const EbookCreator = lazy(() => import("../Pages/Private/Plans/EbookCreator"));
const MinimalGuide = lazy(() => import("../Pages/Private/Guide/MinimalGuide"));
const FirstSetup = lazy(() => import("../Pages/Private/FirstSetup/FirstSetup"));
const MainContainer = lazy(() => import("../Pages/Private/MainContainer"));
const PrelaunchDetails = lazy(() => import("../Pages/Private/PreLaunch/PrelaunchDetails"));
const LandingV2 = lazy(() => import("../Pages/Private/NewWebsite/LandingV2"));
const LandingLobby = lazy(() => import("../Pages/Private/NewWebsite/LandingLobby"));
const Purchases = lazy(() => import("../Pages/Private/Purchases/Purchases"));
const PhysicalProductsDetails = lazy(() => import('../Pages/Private/PhysicalProducts/PhysicalProductsDetails'));
const LegalInfo = lazy(() => import("../Pages/Private/Legal/LegalInfo"));
const OsiteLegalInfo = lazy(() => import("../Pages/Public/OsiteLegalInfo"));
const ProfitCalculator = lazy(() => import('../Pages/Public/ProfitCalculator'));
const ProfitCalculatorResults = lazy(() => import('../Pages/Public/ProfitCalculatorResults'));
const PromotionManager = lazy(() => import('../Pages/Private/Billing/PromotionManager'));
const CampaignEditor = lazy(() => import('../Pages/Private/Billing/CampaignEditor'));

function PrivateOutlet({ children }) {
    const cookies = new Cookies();
    const token = cookies.get("token");
    return !!token ? children : <Navigate to="/login" />;
}

function WebbsitePrivateOutlet({ children }) {
    const cookies = new Cookies();
    const token = cookies.get("token");
    let { url } = useParams()
    return !!token ? children : <Navigate to={"/store/" + url} />;
}

function PublicOutlet({ children }) {
    const cookies = new Cookies();
    const token = cookies.get("token");
    return !!token ? <Navigate to="/dashboard" /> : children;
}


function CookieWarning({ cookies_update = null }) {
    const cookies = new Cookies();
    const cookieConsent = cookies.get("cookieConsent");
    if (!cookieConsent) {
        return <CookieConsent
            onAccept={() => {
                cookies.set("cookieConsent", true)
                cookies_update(true)
            }}
            location="bottom"
            buttonText="Aceitar todas"
            cookieName="Consent_cookie"
            style={{ background: "#2B373B", fontSize: "14px" }}
            buttonStyle={{ background: "white", color: "black", fontSize: "13px" }}
            enableDeclineButton
            expires={150}
            declineButtonText="Apenas essenciais"
            declineButtonStyle={{ background: "black", color: "white", fontSize: "13px" }}
            onDecline={() => {
                cookies.set("cookieConsent", false)
            }}
        >
            ATENÇÃO Este site utiliza cookies funcionais e não funcionais. Ao navegar no site estará a consentir a sua utilização{" "} e a concordar com a  <a href='/PP.pdf' download className="text-blue-400">Política de Privacidade</a> e os  <a href='/T&C.pdf' download className="text-blue-400">Termos e condições</a>
        </CookieConsent>
    }
}

function MainRouter() {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const [cookiesUpdate, setCookiesUpdate] = useState(null)
    useEffect(() => {
        const cookies = new Cookies();
        const cookieConsent = cookies.get("cookieConsent");
        if (cookieConsent) {
            clarity.init(process.env.REACT_APP_CLARITY_KEY);
            clarity.identify('USER_ID', { userProperty: 'value' });
            clarity.consent();
            clarity.setTag('key', 'value');
            clarity.upgrade('upgradeReason');
            if (clarity.hasStarted()) {
                clarity.identify(token, { userProperty: 'value' });
            }
            ReactGA.initialize([
                { trackingId: process.env.REACT_APP_GOOGLE_TAG },
            ]
            )
        }
    }, [cookiesUpdate])



    return (
        <BrowserRouter >
            <CookieWarning cookies_update={setCookiesUpdate} />
            <Suspense fallback={<FullScreenLoaderBlack />}>
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:post_id" element={<BlogPost />} />
                    <Route path="/promotion" element={<PromotionLanding />} />
                    <Route path="/calculadora" element={<ProfitCalculator />} />
                    <Route path="/estimativa/:min/:max" element={<ProfitCalculatorResults />} />
                    <Route path="/hi" element={<Questionaire />} />
                    <Route path="/docs" element={<DocumentationLobby />} />
                    <Route path="/login" element={<PublicOutlet><Login /></PublicOutlet>} />
                    <Route path="/register" element={<PublicOutlet><Register /></PublicOutlet>} />
                    <Route path="/promo/:period" element={<PublicOutlet><RegisterPremium /></PublicOutlet>} />
                    <Route path="/email_confirmation" element={<EmailConfirmation />} />
                    <Route path="/request_password_change" element={<RequestPasswordChange />} />
                    <Route path="/change_password" element={<PasswordChange />} />
                    <Route path="/document/:planId" element={<DocumentCreator />} />
                    <Route path="/document/mobile/:planId" element={<MobileDocumentCreator />} />
                    <Route path="store/:url/item_detail/:item_id" element={<DetailItemPage />} />
                    <Route path="/store/:url" element={<LangingPage />} />
                    <Route path="store/:url/viewer/course/:course_id/video/:video_id" element={<VideoViewer />} />
                    <Route path="store/:url/viewer/course/:course_id" element={<VideoViewer />} />
                    <Route path="/legal_info" element={<OsiteLegalInfo />} />

                    <Route
                        path="/main"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Guia">
                                    <MinimalGuide></MinimalGuide>
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/landing_preview/:page_url"
                        element={
                            <PrivateOutlet>
                                <LandingLobby currentTab="Guia">
                                    <LandingV2 />
                                </LandingLobby>
                            </PrivateOutlet>
                        }
                    />

                    <Route
                        path="/product/:product_id/prelaunch/create"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Product">
                                    <PrelaunchDetails />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/product/:product_id/prelaunch/:prelaunch_id/create"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Product">
                                    <PrelaunchDetails />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/product/:product_id/physical/create"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Product">
                                    <PhysicalProductsDetails />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/product/:product_id/physical/:id/create"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Product">
                                    <PhysicalProductsDetails />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />





                    <Route
                        path="/first_setup"
                        element={
                            <PrivateOutlet>
                                <FirstSetup></FirstSetup>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/product/:product_id/course/create"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Products" >
                                    <CourseCreator />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/product/:product_id/course/:course_id/create"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Products" >
                                    <CourseCreator />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/product/:product_id/course/:course_id/video/:order/create"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Products" >
                                    <VideoUpload />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/product/:product_id/course/:course_id/video/:order/edit/:id"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Products" >
                                    <VideoUpload />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />

                    <Route
                        path="/product/:product_id/course/:course_id/parts"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Products" >
                                    <CoursePartsEditor />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />

                    <Route
                        path="/document/save/:planId"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Products" >
                                    <DocumentSaver destination="/products" />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/guide"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Guide" >
                                    <MinimalGuide />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Dashboard" >
                                    <Purchases />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />


                    <Route path="/store/:url/user_area" element={
                        <WebbsitePrivateOutlet >
                            <UserAreaPage />
                        </WebbsitePrivateOutlet>}
                    />
                    <Route path="/landingV1" element={
                        <PrivateOutlet>
                            <MainContainer currentTab="YourWebsite" >
                                <LandingEditor className />
                            </MainContainer>
                        </PrivateOutlet>} />
                    <Route
                        path="/billing"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Billing" >
                                    <BillingSetup />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />

                    <Route
                        path="/products"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Products" >
                                    <ListProducts />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/campaign_manager"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Promotion Manager" >
                                    <PromotionManager />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/campaign_editor"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Campaign Editor" >
                                    <CampaignEditor />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/campaign_editor/:campaign_uuid"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Campaign Editor" >
                                    <CampaignEditor />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />

                    <Route
                        path="/product/create"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Products" >
                                    <ProductCreator />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />

                    <Route
                        path="/product/:product_id/create"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Products" >
                                    <ProductCreator />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />



                    <Route
                        path="/workout/create"
                        element={
                            <PrivateOutlet >
                                <MainContainer >
                                    <CreateWorkoutSteps step={1}></CreateWorkoutSteps>
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />


                    <Route
                        path="/product/:product_id/ebook/create"
                        element={
                            <PrivateOutlet >
                                <MainContainer currentTab="Products" >
                                    <EbookCreator />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />

                    <Route
                        path="/product/:product_id/ebook/:planId/create"
                        element={
                            <PrivateOutlet >
                                <MainContainer currentTab="Products" >
                                    <EbookCreator></EbookCreator>
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />

                    {/*Settings */}
                    {/*   Account */}
                    <Route
                        path="/store_settings"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="StoreSettings" >
                                    <StoreSettings />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/account_settings"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Settings" >
                                    <UserSettings tabName="Account">
                                        <AccountSettings />
                                    </UserSettings>
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    {/*   Billing*/}
                    {/*<Route
                        path="/empty_subscriptions"
                        element={
                            <PrivateOutlet>
                                <MainContainer >
                                    <UserSettings tabName="Plans">
                                        <BillingController />
                                    </UserSettings>
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />*/}
                    <Route
                        path="/view_subscriptions"
                        element={
                            <PrivateOutlet>
                                <MainContainer >
                                    <ViewSubscriptions />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/user_plans"
                        element={
                            <PrivateOutlet>
                                <MainContainer >
                                    <ViewPlans />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/video_preview/:url/:course_id/:video_id"
                        element={
                            <PrivateOutlet>
                                <MainContainer >
                                    <VideoPreviewer />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/payment_option_details"
                        element={
                            <PrivateOutlet>
                                <MainContainer >
                                    <UserSettings tabName="Plans">
                                        <BillingController forceRefresh={true}>
                                            <ChoosePlans />
                                        </BillingController>
                                    </UserSettings>
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />

                    <Route
                        path="/plugins"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Plugins" >
                                    <Plugins />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />

                    <Route
                        path="/legal"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Legal" >
                                    <LegalInfo />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />
                    <Route
                        path="/end_users"
                        element={
                            <PrivateOutlet>
                                <MainContainer currentTab="Utilizadores" >
                                    <EndUserList />
                                </MainContainer>
                            </PrivateOutlet>
                        }
                    />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>

        </BrowserRouter >
    )
}

export default MainRouter
