import React from 'react'
import Loader from './Loader'

function FullScreenLoader() {
    return (
        <div className=' h-screen bg-zinc-900/20 flex justify-center items-center'>
            <Loader ></Loader>
        </div>
    )
}

export default FullScreenLoader
