import React from 'react'

function SectionHeader({ title, subtitle }) {
    return (
        <div className="border-b px-1 py-2 ">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-4">
                    <h3 className="text-2xl font-semibold leading-6 text-black">{title}</h3>
                    <p className="mt-2 text-base text-gray-500">
                        {subtitle}
                    </p>
                </div>
            </div>

        </div>
    )
}

export default SectionHeader